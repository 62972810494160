import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TrademaxAPIService } from './trademaxapi.service';
import { BroadcastService, EVENTS } from './services/broadcast.service';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { ClientDetails } from './models/client-details';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Analytics } from './analytics';
import { CustomTrackingService } from './custom-tracking.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-body',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title: string;
  logo: string;
  apiKey: string;
  lang: string;
  loading = false;
  country: any;
  locationPath: string;
  isCarbravo: boolean;
  dealer_config: ClientDetails;

  constructor(
    localStorageService: LocalStorageService,
    private location: Location,
    private translateService: TranslateService,
    private __trademaxapiService: TrademaxAPIService,
    private broadcastService: BroadcastService,
    private router: Router,
    private customTrackingService: CustomTrackingService
  ) {
    Analytics.initializeTracker(environment.GA_MEASURE_ID);
    this.translateService.use('en');

    if (localStorageService.isLocalStorageAvailable == false) {
      this.broadcastService.showMessageDialog({
        title: 'general.private_window.title',
        message: 'general.private_window.error',
      });
    }

    this.broadcastService.clientDetails.subscribe((details: ClientDetails) => {
      this.country = details?.country;
    });
    this.broadcastService.subscribe(EVENTS.ERROR_EVENT, (err: HttpErrorResponse) => {
      console.error(err);
      let message = 'general.error.message';
      if (err.status == null) {
        //Handle timeout error that is isn't a HttpErrorResponse
        message = err.message;
      } else if (err.status === 0 || err.status === 500) {
        message = 'general.error.message';
      } else {
        message = err.error?.Message || `Status: ${err.status} ${err.statusText}`;
      }

      this.broadcastService.showMessageDialog({
        title: 'general.error.title',
        message: message,
        buttons: [{ title: 'general.button.close' }],
      });
    });

    this.broadcastService.appLoadedEvent.subscribe((loaded) => {
      if (loaded) {
        setTimeout(() => {
          window['app_loaded'] = true;
          this.hideLoadingScreen();
        }, 100);
      }
    });

    this.broadcastService.clientDetails.subscribe((data) => {
      if (!data) {
        return;
      }

      localStorageService.setToken(data.token);

      this.logo = data.logo;
      this.title = data.title;
      this.setRootCss(data.css);

      this.hideLoadingScreen();

      //if client GA Id provided
      if (data.client_ga) {
        Analytics.addSecondaryTracker(data.client_ga);
      }

      Analytics.setSessionParams({
        api_key: data.apikey,
        user_name: data.userName,
        token: data.token,
      });
    });

    this.customTrackingService.init();
  }
  ngOnInit(): void {
    this.router.events.subscribe((val) => {
      const locationPath = this.location.path();
      if (this.router.navigated && this.locationPath != locationPath) {
        this.locationPath = locationPath;
        const parts = locationPath.split(/\/|\?|$/);

        const params = this.router.getCurrentNavigation().finalUrl?.queryParams || [];

        let lang = params['lang'];
        if (!['en', 'fr'].includes(lang)) {
          lang = 'en';
        }
        this.translateService.use(lang);
        this.broadcastService.emitSelectedLanguage(lang);

        if (parts.includes('embed')) {
          if (params['apiKey'] && params['apiKey'] != this.apiKey) {
            this.getUpdateClientDetails(params['apiKey']);
          }
          if (params['provider_program_id']) {
            this.broadcastService.emitProviderProgramId(params['provider_program_id']);
          }
        }
      }
    });
  }

  getUpdateClientDetails(apiKey) {
    this.loading = true;
    this.apiKey = apiKey;

    const fallbackGet = (apiKey) => {
      this.__trademaxapiService
        .getClientDefaults(apiKey)
        .toPromise()
        .then((data) => {
          this.broadcastService.emitClientDetails(data);
        })
        .catch((err: HttpErrorResponse) => {
          this.hideLoadingScreen();
          console.error(err);
          this.broadcastService.showMessageDialog({
            title: 'general.error.title',
            message: err.error?.Response?.code ? err.error?.Message : 'general.error.message',
            buttons: [
              {
                title: 'general.button.retry',
                callback: () => setTimeout(() => this.getUpdateClientDetails(apiKey), 0),
              },
            ],
          });
        });
    };

    if (window['get-trademax-config-by-api-key']) {
      //loding-screen.js script already fetched the data
      window['get-trademax-config-by-api-key']
        .then((data) => {
          if (data?.apikey == apiKey) {
            this.broadcastService.emitClientDetails(data);
          } else {
            fallbackGet(apiKey);
          }
        })
        .catch((err) => {
          console.log(err);
          fallbackGet(apiKey);
        });
    } else {
      //Script didnt load for some reason
      fallbackGet(apiKey);
    }
  }

  hideLoadingScreen(showAppBody = true) {
    //Get the loading-screen DOM element
    const loading_screen = document.getElementById('loading-screen');
    //calculate for how long the screen has been displaying
    const loading_screen_present_for_ms = loading_screen
      ? Date.now() - Number(loading_screen.getAttribute('created'))
      : 3000;
    //make sure total loading screen show time is at least 3000ms
    const hide_screen_in_ms = Math.max(3000 - loading_screen_present_for_ms, 0);

    setTimeout(() => {
      if (showAppBody) this.loading = false;
      if (loading_screen) loading_screen.remove();
    }, hide_screen_in_ms);
  }

  setRootCss(css: any): void {
    //loading-screen.js might not have been able to inject the custom css (if the host url was not recognized, for example).
    const customCss = document.getElementById('custom-css');
    if (customCss) {
      //move custom-css to the last position to make sure it overrides everything
      customCss.remove();
      //document.head.append(customCss);
    }

    //apply custom css
    try {
      //css may be a json file
      const cssRoot = JSON.parse(css);
      Object.keys(cssRoot).forEach(function (key) {
        document.documentElement.style.setProperty(`--${key}`, cssRoot[key]);
      });
    } catch (err) {
      //if its not a json, treat it as a css file. simply append it to the end of the head
      const style = document.createElement('style');
      style.id = 'custom-css';
      style.textContent = css;
      document.head.appendChild(style);
    }
  }
}
