// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'dev',
  production: false,
  imageUrl: `https://fuelapi.canadianblackbook.com/`,
  trademaxapiUrl: `https://trademax-api-dev-azure.blackbookcloud.com/`,
  GA_MEASURE_ID: 'G-TRH47LDMB3',
  applicationType: `application/json`,
  thirdPartyScripts: {
    carbravo_tracking: 'https://assets.adobedtm.com/ea8c57fea068/bd0eb00329a9/launch-dae692ec7b36-development.min.js',
  },
  captcha_site_key: '6LfQB8wnAAAAALhgzgWKk92qJH6GZMAOvr4BDlk_',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
