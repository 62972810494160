import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { routes } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TrademaxAPIService } from './trademaxapi.service';
import { UsedCarsService } from './services/used-cars.service';
import { AuthInterceptorProviders } from './auth.interceptor';
import { NgxMaskPipe } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BroadcastService } from './services/broadcast.service';

import { MessageModule } from './message/message.module';
import { ErrorHandlingModule } from './services/global-error.handler.module';
import { CustomTrackingService } from './custom-tracking.service';
import { MultiTranslateHttpLoader } from './multi-translator-loader';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot(routes),
    NgxMaskPipe,
    MessageModule,
    ErrorHandlingModule,
  ],
  providers: [BroadcastService, AuthInterceptorProviders, TrademaxAPIService, UsedCarsService, CustomTrackingService],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  //return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  return new MultiTranslateHttpLoader(http, {
    resources: [
      //{ prefix: './assets/i18n/trader/', suffix: '.json' },
    ],
  });
}
