export interface ScheduledJobTaskTypeDoc {
  config: object;
  description: string;
}
export interface ScheduledJobTaskType {
  name: string;
  config: object;
  doc: ScheduledJobTaskTypeDoc;
}

export interface ScheduledJob {
  id?: number;
  method_name?: string;
  job_name: string;
  description: string;
  job_settings: string;
  max_retries: number;
  retry_delay: number;
  failure_count: number;
  is_enabled: boolean;
  is_notify_on_error: boolean;
  only_latest_history: boolean;
  created_at?: Date;
  last_completed_at?: Date;
  schedule?: string;
  histories?: ScheduledJobHistory[];
}

export function ScheduledJobMap(data) {
  return <ScheduledJob>{
    id: data.id,
    method_name: data.method_name,
    job_name: data.job_name,
    description: data.description,
    job_settings: data.job_settings,
    max_retries: data.max_retries,
    retry_delay: data.retry_delay,
    failure_count: data.failure_count,
    is_enabled: data.is_enabled,
    is_notify_on_error: data.is_notify_on_error,
    only_latest_history: data.only_latest_history,
    created_at: dateOrNull(data.created_at),
    last_completed_at: dateOrNull(data.last_completed_at),
    schedule: data.schedule,
    status: data.status,
    last_started: dateOrNull(data.last_started),
    last_completed: dateOrNull(data.last_completed),
    runtime: data.runtime,
    histories: (data.histories || []).map(ScheduledJobHistoryMap),
  };
}

function dateOrNull(value) {
  return value ? new Date(value) : null;
}

export interface ScheduledJobHistory {
  id: number;
  job_id: number;
  retries: number;
  message?: string;
  error?: string;
  status: number;
  created_at: Date;
  completed_at?: Date;
}

export function ScheduledJobHistoryMap(data) {
  return <ScheduledJobHistory>{
    id: data.id,
    job_id: data.job_id,
    retries: data.retries,
    message: data.message,
    error: data.error,
    status: data.status,
    created_at: dateOrNull(data.created_at),
    completed_at: dateOrNull(data.completed_at),
  };
}
