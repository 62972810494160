import { Component } from '@angular/core';
import { MessageData, MessageDataButton } from 'src/types/MessageData';
import { BroadcastService } from '../services/broadcast.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  public messages: MessageData[] = [];

  constructor(private br: BroadcastService) {
    this.br._messageDialog.subscribe((msg) => {
      if (msg.buttons == null) {
        msg.buttons = [];
      }
      this.messages.push(msg);
    });
  }

  buttonClicked(msg: MessageData, button: MessageDataButton) {
    this.messages = this.messages.filter((m) => m != msg);
    if (button.callback) button.callback();
  }
}
