import { ErrorHandler, Injectable } from '@angular/core';
import { Analytics } from '../analytics';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    console.error(error);
    Analytics.pushException(error);
  }
}
