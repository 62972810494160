import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: '404', pathMatch: 'full' },
  {
    path: '404',
    loadChildren: () => import('./page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
  },
  { path: 'embed', loadChildren: () => import('./vehicle/vehicle.module').then((m) => m.VehicleModule) },
  {
    path: 'trader/embed',
    loadChildren: () => import('./trader-vehicle/trader-vehicle.module').then((m) => m.TraderVehicleModule),
  },
  { path: 'carbravo/embed', loadChildren: () => import('./vehicle/vehicle.module').then((m) => m.VehicleModule) },
  {
    path: 'widget',
    loadChildren: () => import('./widgetconfig/widgetconfig.module').then((m) => m.WidgetConfigModule),
  },
  { path: 'reporting', loadChildren: () => import('./reporting/reporting.module').then((m) => m.ReportingModule) },
  { path: 'login', loadChildren: () => import('./login-dialog/login-dialog.module').then((m) => m.LoginDialogModule) },
  {
    path: 'viewemail/:country/:guid',
    loadChildren: () => import('./email-view/email-view.module').then((m) => m.EmailViewModule),
  },
  {
    path: 'reviseoffer/:guid',
    loadChildren: () => import('./revise-lead/revise-lead.module').then((m) => m.ReviseLeadModule),
  },
  {
    path: 'test-bench',
    loadChildren: () => import('./test-bench/test-bench.module').then((m) => m.TestBenchModule),
  },
  { path: '**', redirectTo: '404', pathMatch: 'full' },
];
