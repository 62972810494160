import { environment } from 'src/environments/environment';
import { Utils } from './utils';

declare global {
  interface Window {
    gtag: any;
    dataLayer: any[];
  }
}

export class Analytics {
  static sessionParams: any;
  static measurementID: string;
  static dataLayers: any;
  static primaryTrackerId: string;

  static initializeTracker(measurement_id: string) {
    //Make sure we load tag manager only once!
    Utils.injectScriptLink('head', `https://www.googletagmanager.com/gtag/js?id=${measurement_id}`);
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());

    this.addSecondaryTracker(measurement_id);
    this.primaryTrackerId = measurement_id;
  }

  static addSecondaryTracker(measurement_id: string) {
    window.gtag('config', measurement_id, {
      send_page_view: false,
      debug_mode: !environment.production,
    });
  }

  static setSessionParams(params: any) {
    this.sessionParams = params;
  }

  static pushEvent(event_name: string, event_params: object) {
    window['gtag']('event', event_name, event_params);
  }
  static pushException(error: any) {
    this.pushEvent('exception', {
      send_to: this.primaryTrackerId,
      description: error,
      stack: error?.stack,
      fatal: false,
    });
  }
  static pushPageView(page_title: string) {
    window['gtag']('event', 'page_view', {
      page_title: page_title,
      page_location: window.location.href, // Include the full URL
    });
    this.pushEvent(page_title, this.sessionParams);
  }
}
