import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, from, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public isLocalStorageAvailable = false;
  constructor() {
    const test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      this.isLocalStorageAvailable = true;
    } catch (e) {
      this.isLocalStorageAvailable = false;
    }
  }
  public setItem(key: string, value: string) {
    if (!this.isLocalStorageAvailable) return null;
    localStorage.setItem(key, value);
  }

  public getItem(key: string) {
    if (!this.isLocalStorageAvailable) return null;
    return localStorage?.getItem(key);
  }

  public removeItem(key: string) {
    if (!this.isLocalStorageAvailable) return null;
    localStorage.removeItem(key);
  }

  public clear() {
    if (!this.isLocalStorageAvailable) return null;
    localStorage.clear();
  }

  setToken(token_id: string) {
    const last_token = JSON.parse(this.getItem('TOKEN') || 'null');
    const token = {
      TOKEN: token_id,
      TOKEN_TIME: last_token != null && token_id != last_token.TOKEN ? last_token['TOKEN_TIME'] : new Date().toString(),
      TOKEN_LAST_UPDATED: new Date().toString(),
    };
    this.setItem('TOKEN', JSON.stringify(token));
  }

  getToken() {
    let token = JSON.parse(this.getItem('TOKEN') || 'null');
    if (token != null) {
      token.TOKEN_TIME = new Date(token.TOKEN_TIME);
      token.TOKEN_LAST_UPDATED = new Date(token.TOKEN_LAST_UPDATED);
    } else {
      token = {
        TOKEN: '',
      };
    }

    return token;
  }
}
