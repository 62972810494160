import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BroadcastService } from './services/broadcast.service';
import { timeout, tap, catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  authorization: string;
  recaptcha: string;
  constructor(
    private LocalStorageService: LocalStorageService,
    private broadcastService: BroadcastService,
    private router: Router
  ) {
    this.broadcastService.googleRecaptcha.subscribe((googleRecaptcha) => {
      this.recaptcha = googleRecaptcha;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.broadcastService.emitRequestStart();
    if (this.LocalStorageService.getToken() != null) {
      request = request.clone({
        withCredentials: true,
        headers: request.headers
          .set('Token', this.LocalStorageService.getToken().TOKEN)
          .set('apikey', this.broadcastService.clientDetails.value?.apikey || ''),
      });
    }
    if (this.broadcastService.authorization.value) {
      request = request.clone({
        withCredentials: true,
        headers: request.headers.set('authorization', this.broadcastService.authorization.value),
      });
    }
    //Trademax-335 adding google recaptcha
    if (
      this.broadcastService.clientDetails.value?.enable_recaptcha &&
      (request.url.includes('get-gtv') ||
        request.url.includes('get-estimated-tradevalue') ||
        request.url.includes('get-trader-tradevalue')) &&
      this.recaptcha
    ) {
      request = request.clone({
        withCredentials: true,
        headers: request.headers.set('google_recaptcha_token', this.recaptcha),
      });
    }

    //Add lead_source to all get-gtv and get-estimated-tradevalue requests
    if (request.url.includes('get-gtv') || request.url.includes('get-estimated-tradevalue')) {
      request = request.clone({
        headers: request.headers.set('lead_source', 'TRADEMAX-UI'),
      });
    }

    return next.handle(request).pipe(
      timeout(getTimeout(request)),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //this.broadcastService.emitRequestEnd();
          /*const authorization = event.headers.get('Authorization');
          if (authorization) {
            this.broadcastService.authorization = authorization;
          }*/
        }
        return event;
      }),
      catchError((err: any) => {
        //this.broadcastService.emitRequestEnd();
        if (err.status === 401 || err.status === 403) {
          //redirect to login page
          this.router.navigate(['/login']);
          this.broadcastService.emitAuthorization(null);
        }
        return throwError(err);
      }),
      finalize(() => {
        this.broadcastService.emitRequestEnd();
      })
    );
  }
}

function getTimeout(request: HttpRequest<any>) {
  switch (true) {
    case request.url.includes('/leads'):
      return 120000;
    case request.url.includes('/dealer-locator/search'):
      return 20000;
    case request.url.includes('/get-gtv'):
      return 15000;
    case request.url.includes('/get-estimated-tradevalue'):
      return 15000;
    case request.url.includes('/get-trader-tradevalue'):
      return 15000;
    default:
      return 10000;
  }
}

export const AuthInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }];
